import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardApi from "../../api/DashboardApi";
import axios from 'axios';

const savedUser = localStorage.getItem('user');


// const accessTokenExpiry = new Date(JSON.parse(savedUser).access_token_expiry );
//   const currentTime = new Date();
//   console.log(accessTokenExpiry,"accessTokenExpiry",currentTime)
  // if(savedUser){

  //   console.log(JSON.parse(savedUser).access_token_expiry ,"accessTokenExpiry",currentTime)
  // }
 


export const fetchAsyncAsset = createAsyncThunk('untapped/assets', async ({user_info,searchQuery,currentPage}) => {


  const response = await DashboardApi.get(`/assets/?page=${currentPage}&search=${searchQuery}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${user_info.access}`
    },
  })
  return response.data;
})


export const fetchAsyncRiders = createAsyncThunk('untapped/riders', async (user) => {
  const response = await DashboardApi.get(`/riders/?page=${user.currentPage}&search=${user.searchQuery}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.user_info.access}`
    },
  })
  return response.data;
})


export const fetchAsyncAssigned = createAsyncThunk('untapped/assets/assign', async ({user_info,searchQuery,currentPage}) => {
  const response = await DashboardApi.get(`/assets/assign?page=${currentPage}&search=${searchQuery}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${user_info.access}`
    },
  })
  return response.data;
})

export const fetchAsyncPayment = createAsyncThunk('untapped/rider/payment', async ({user_info,searchQuery,currentPage,firstDayOfWeek,lastDayOfWeek,lastDayOfMonth,firstDayOfMonth,selectedRange}) => {

  if(firstDayOfWeek){
    const response = await DashboardApi.get(`/riders/payment/history?page=${currentPage}&date_from=${firstDayOfWeek}&date_to=${lastDayOfWeek}&search=${searchQuery}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user_info.access}`
      },
    })
    return response.data;
  }else if(firstDayOfMonth){
    const response = await DashboardApi.get(`/riders/payment/history?page=${currentPage}&date_from=${firstDayOfMonth}&date_to=${lastDayOfMonth}&search=${searchQuery}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user_info.access}`
      },
    })
    return response.data;
  }else if(selectedRange.length){
    
    const response = await DashboardApi.get(`/riders/payment/history?page=${currentPage}&date_from=${selectedRange[0]}&date_to=${selectedRange[1]}&search=${searchQuery}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user_info.access}`
      },
    })
    return response.data;
  }
  
  
})

export const fetchAsyncReceipts = createAsyncThunk('untapped/rider/receipts', async ({user_info,selectedRange,currentPage,filterStatus,searchQuery}) => {
  
  const response = await DashboardApi.get(`https://morideapi.groupeya.com/api/v1/riders/payment/receipt/?page=${currentPage}&date_from=${selectedRange[0]}&date_to=${selectedRange[1]}&search=${searchQuery}&payment_status_id=${filterStatus}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${user_info.access}`
    },
  }) 
 
  return response.data;
})

export const fetchAsyncLoggedinUser = createAsyncThunk('untapped/user-accounts', async ({user_info}) => {
 
  const response = await DashboardApi.get(`/accounts/edit/`,{
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${JSON.parse(savedUser.access)}`
    },
  }) 
  
 
  return response.data;
})

// application list 
export const fetchAsyncApplicantsApplications = createAsyncThunk('applicatants/applications', async ({searchInput}) => {
  console.log('searchInput request',searchInput)
  
  const response = await axios.get(`https://applicationbackend.jalikoi.rw/api/v1/application/search?query=${searchInput}`) 
  
  const { data } = response;
  return data;
})

const initialState = {
  riderList: {},
  paginatedRiderList:{},
  assetList: [],
  ridersPayments:[],
  ridersPaymentsPaginatedList:[],
  ridersReceiptsList:[],
  paginatedRidersReceiptsList:[],
  paginatedassetLists: [],
  assignedAssetList: [],
  paginatedassignedAssetList: [],
  displayAsset:[],
  displayRider:[],
  isLoadingApplications: false,
  user: savedUser ? JSON.parse(savedUser) : null,
  loggedinuserInfo:null,
  applicatantsApplications:null
};


const untappedSlice = createSlice({
  name: "untapped",
  initialState,
  reducers: {
    addRiders: (state, action) => {
      state.riderList = action.payload; 
    },
  },
  extraReducers: {

    [fetchAsyncApplicantsApplications.pending]: (state) => {
      console.log('isLoadingApplications',);
      state.isLoadingApplications = true;
    },
    [fetchAsyncLoggedinUser.pending]: (state) => {
      state.isLoading = true;
    },

    [fetchAsyncApplicantsApplications.fulfilled]: (state, { payload }) => {
      return { ...state, isLoadingApplications: false, applicatantsApplications: payload };
    },

    [fetchAsyncLoggedinUser.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false,loggedinuserInfo:payload };
    },
    [fetchAsyncLoggedinUser.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncReceipts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncReceipts.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false,paginatedRidersReceiptsList:payload.results,ridersReceiptsList:payload };
    },
    [fetchAsyncReceipts.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncAsset.pending]: (state) => {
      state.isLoading = true;
    },

    [fetchAsyncAsset.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false, assetList:payload.results,paginatedassetLists:payload };
    },
    [fetchAsyncAsset.rejected]: (state) => {
      state.isLoading = false;
    },
    
    [fetchAsyncRiders.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncRiders.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false, riderList: payload.results,paginatedRiderList:payload };
    },
    [fetchAsyncRiders.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchAsyncAssigned.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncAssigned.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: true, assignedAssetList: payload.results ,paginatedassignedAssetList:payload};
    },
    [fetchAsyncAssigned.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchAsyncPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncPayment.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false, ridersPayments: payload.results,ridersPaymentsPaginatedList:payload };
    },
    [fetchAsyncApplicantsApplications.rejected]: (state) => {
      state.isLoadingApplications = false;
    },
    [fetchAsyncPayment.rejected]: (state) => {
      state.isLoading = false;
    },
  }
});


export const { addRiders } = untappedSlice.actions;
export const getAllRiders = (state) => state.untapped.riderList;
export const getAllpaginatedRiders = (state) => state.untapped.paginatedRiderList;
export const getAllAsset = (state) => state.untapped.assetList;
export const getAllAssignedAsset = (state) => state.untapped.assignedAssetList;
export const getAllpaginatedAsset = (state) => state.untapped.paginatedassetLists;

export const getAllridersPayments = (state) => state.untapped.ridersPayments;
export const getAllridersPaymentsPaginatedList = (state) => state.untapped.ridersPaymentsPaginatedList;

export const getAllpaginatedAssignedAsset = (state) => state.untapped.paginatedassignedAssetList;
// application list response 
export const getAllApplicantsApplications = (state) => state.untapped.applicatantsApplications;
export const loadingAlldata = (state) => state.untapped.isLoadingApplications;

export const getAllRidersReceipts = (state) => state.untapped.ridersReceiptsList;
export const getAllPaginatedRidersReceipts = (state) => state.untapped.paginatedRidersReceiptsList;
export const getUser = (state) => state.untapped.user;
export const getloggedinUser = (state) => state.untapped.loggedinuserInfo;

export default untappedSlice.reducer;
